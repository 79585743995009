
import { Component, Vue, Watch } from "vue-property-decorator";
import { apiSdvideoSourceLists,apiSdvideoSourceDel } from "@/api/sdvideo";
import MaterialSelect from "@/components/material-select/index.vue";
import { RequestPaging } from '@/utils/util'
import { PageMode } from '@/utils/type'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import SdvideoSourceEdit from '@/components/sdvideo/sdvideo-source-edit.vue'

@Component({
    components: {
        LsDialog,
        LsPagination,	
        MaterialSelect,
		SdvideoSourceEdit
    },
})
export default class Source extends Vue {

    // 分页
    pager: RequestPaging = new RequestPaging()

    // 搜索表单
    searchForm = {
        type:'',
    }

    type_options = [
        { value: '', label: '全部' },
        { value: 'img', label: '模特图' },
        { value: 'bg', label: '背景图' },
        { value: 'video', label: '动作视频' },
    ]

    /** S Methods **/

    // 获取列表
    getList() {
        this.pager
            .request({
                callback: apiSdvideoSourceLists,
                params: {...this.searchForm},
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 删除
    onSourceDel(row: any) {     
	   apiSdvideoSourceDel({
            id: row.id
        }).then(() => {
            this.getList()
        })
    }

    // 搜索
    onSearch() {
        this.pager.page = 1
        this.getList()
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getList()
    }

    /** E Life Cycle **/

}
